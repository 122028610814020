<template>
    <div style="width: 100vw;height: 100vh;display: flex;flex-direction: column;">
        <Header :title="'可能点充值'" :leftClick="goBack"></Header>
        <div style="width: 100%;height: 1px;background-color: #F0F1F5;"></div>
        <div class="product-content">
            <div v-for="(product, index) in dataList" :key="index"
                :class="product.selected ? 'product-item-selected' : 'product-item'" @click="selectProduct(index)">
                <p :class="product.selected ? 'product-list-price-selected' : 'product-list-price'"><span
                        style="font-size: 24px;">{{ product.product_list_price }}</span>可能点</p>
                <p :class="product.selected ? 'product-price-selected' : 'product-price'">售价：{{ product.product_price }}元
                </p>
                <p class="product-intro" v-if="product.product_intro && product.product_intro != ''">{{
                    product.product_intro }}</p>
            </div>
        </div>
        <div class="btn-purchase" @click="comfirmPurchse"> 立即充值</div>
    </div>
</template>
<script>
import {
    getAndroidPricesByProduct,
    createProductOrder,
    wx_pay_fwh
} from '../../api/app'

import {
    getSignPackageFWH,
} from '../../api/keneng'

import Header from "../../components/app/Header.vue";
import {
    getAppUserId
} from '../../utils/store'
export default ({
    name: 'index',
    components: {
        Header,
    },
    data() {
        return {
            user_id: getAppUserId(),
            dataList: [],
            selectedProduct: {}
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {

        comfirmPurchse() {
            let product = null
            for (let i = 0; i < this.dataList.length; i++) {
                let content = this.dataList[i]
                if (content['selected'] == true) {
                    product = content
                    break
                }
            }
            if (product == null) {
                this.$message.error('请选择产品')
                return
            }
            //创建产品订单，然后发起支付
            createProductOrder(product._id.$id).then((res) => {
                let result = res.data
                if (result.code == 0) {
                    this.gotoWeixinPay(result.data._id.$id)
                } else {
                    this.$message.error(result.msg)
                }
            })
        },
        //发起支付
        gotoWeixinPay(order_id) {
            wx_pay_fwh(order_id).then((res) => {
                let result = res.data
                if (result.code == 0) {
                    this.showWXPay(result.data.timeStamp, result.data.nonceStr, result.data.package, result.data.signType, result.data.sign)
                } else {
                    this.$message.error(result.msg)
                }
            })
        },
        showWXPay(timestamp, nonceStr, package1, signType, paySign) {
            let that = this
            let url = window.location.href//this.$route.fullPath
            getSignPackageFWH(url).then((res) => {
                const result = res.data
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: result.appId, // 必填，公众号的唯一标识
                    timestamp: result.timestamp, // 必填，生成签名的时间戳
                    nonceStr: result.nonceStr, // 必填，生成签名的随机串
                    signature: result.signature, // 必填，签名
                    jsApiList: [
                        'chooseWXPay'
                    ] // 必填，需要使用的JS接口列表
                })
            })
            wx.ready(() => {
                wx.chooseWXPay({
                    timestamp: timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                    nonceStr: nonceStr, // 支付签名随机串，不长于 32 位
                    package: package1, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                    signType: signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                    paySign: paySign, // 支付签名
                    success: (res) => {
                        // 支付成功后的回调函数
                        that.$message({
                            message: '缴费成功',
                            type: 'success'
                        })
                        that.goBack()
                    }
                })
            })
            wx.error((err) => {
                console.log(err)
            })
        },
        selectProduct(index) {
            let selected = false
            for (let i = 0; i < this.dataList.length; i++) {
                let content = this.dataList[i]
                if (index == i) {
                    selected = content['selected']
                }
                content['selected'] = false
            }
            this.dataList[index].selected = !selected
        },

        goBack() {
            this.$router.go(-1)
        },

        fetchData() {
            this.dataList = []
            getAndroidPricesByProduct().then((res) => {
                if (res.data.code == 0) {
                    let result = res.data.data
                    for (let i = 0; i < result.length; i++) {
                        let content = result[i]
                        content['selected'] = false
                        this.dataList.push(content)
                    }
                }
            })
        }
    }
})
</script>
<style lang="less" scoped>
.product-intro {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #ff5e45;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 12px;
    padding: 6px 10px;
    box-sizing: border-box;
    color: white;
    font-size: 10px;
}


.product-list-price {
    color: #454651;
    font-size: 14px;
}

.product-list-price-selected {
    .product-list-price();
    color: white;
}

.product-price {
    margin-top: 10px;
    color: #9698a2;
    font-size: 13px;
}

.product-price-selected {
    .product-price();
    color: white;
}

.product-content {
    width: 100%;
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 0px 15px;
    box-sizing: border-box;
    justify-content: center;

    .product-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        background-color: #f5f5f5;
        width: calc(50% - 20px);
        border-radius: 12px;
        height: 100px;
        margin: 10px 10px;
    }

    .product-item-selected {
        .product-item();
        background-color: #696EC1;
    }
}

.btn-purchase {
    cursor: pointer;
    margin: 15px 15px;
    color: white;
    font-size: 16px;
    text-align: center;
    height: 48px;
    line-height: 48px;
    background-color: #5d5bb5;
    border-radius: 34px;
}
</style>
